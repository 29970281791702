<template>
  <div class="wrapper__Show__Reel" v-show="showModal">
    <div class="container__modal">

      <video  rel="prefetch" v-if="video" class="video__modal"  tabindex="0" playsinline autoplay muted loop
             preload="preload"
      >
        <source rel="prefetch" type="video/mp4;" :src="isMobile ? video.mobile_link : video.link" />
      </video>
      <div class="close__modal" @click="closeModalShowReel">
        <img src="@/assets/img/close__modal__reel.svg" alt="close">
      </div>
      <button class="route__reel__page" @click="$router.push('/reel')">
        All Reels
      </button>
    </div>

  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ModalShowReel",
  props: {
    showModal: {
      type: Boolean,
      required: true
    }
  },
  /*watch: {
    showModal() {
      if (this.showModal) {
        if (this.$refs.fullScreen.requestFullscreen)
          this.$refs.fullScreen.requestFullscreen();
        else if (this.$refs.fullScreen.webkitRequestFullscreen)
          this.$refs.fullScreen.webkitRequestFullscreen();
        else if (this.$refs.fullScreen.msRequestFullScreen)
          this.$refs.fullScreen.msRequestFullScreen();
      }
    }
  },*/
  data() {
    return {
      video: null,
    }
  },

  created() {
    axios
        .get('https://back.digitalbeautywork.com/api/reel')
        .then(({data}) => {
          this.video = data.data.video
        });

  },
  methods: {
    closeModalShowReel() {
      this.$emit('close');
    },
  },
  computed: {
    isMobile(){
      console.log(window.innerWidth <= 767)
      console.log(window.innerWidth)
      return window.innerWidth <= 767
    },
  },
}
</script>

<style scoped>
.wrapper__Show__Reel {
  margin: 0 auto;
  background-color: black;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 2560px;
  position: fixed;
  z-index: 5555;
}

.container__modal {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
/*.frame__modal{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
}*/

.video__modal {
  position: absolute;
  /*height: 100%;*/
  /*width: 100vw;*/
  /*width: 100%;*/
  /*height: 100vh;*/
  min-width: 100%;
  min-height: 100%;
  left: 50%;
  transform: translate(-50%);
}

.route__reel__page {
  width: 266px;
  height: 52px;
  background-color: rgba(255, 255, 255, 0.5);
  color: #000000;
  transition: .5s;
  cursor: pointer;
  font-family: 'Benzin-Bold', sans-serif;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  transform: translate(-50%);
  left: 50%;
  z-index: 222;
  border: none;
  margin-bottom: 80px;
}

.route__reel__page:hover {
  background-color: #F81C31;
  color: #FFFFFF;
}

.close__modal {
  position: absolute;
  right: 0;
  margin-top: 40px;
  margin-right: 41px;
  z-index: 3333;
}
</style>